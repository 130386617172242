var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var EntityLocksActionTypes = {
    ENTITY_LOCKS_SET_LOCKS: 'ENTITY_LOCKS_SET_LOCKS',
    ENTITY_LOCKS_UPDATE_LOCK: 'ENTITY_LOCKS_UPDATE_LOCK',
    ENTITY_LOCKS_DELETE_LOCK: 'ENTITY_LOCKS_DELETE_LOCK',
};
var INITIAL_STATE = [];
export function entityLocks(state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case EntityLocksActionTypes.ENTITY_LOCKS_DELETE_LOCK:
            return __spreadArray([], state.filter(function (l) { return l.objectId != action.payload.objectId; }), true);
        case EntityLocksActionTypes.ENTITY_LOCKS_UPDATE_LOCK:
            return __spreadArray(__spreadArray([], state.filter(function (l) { return l.objectId != action.payload.objectId; }), true), [action.payload], false);
        case EntityLocksActionTypes.ENTITY_LOCKS_SET_LOCKS:
            return __spreadArray([], action.payload, true);
        default:
            return state;
    }
}
