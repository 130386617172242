import { boardConstants, userConstants, binderConstants, fileConstants, minutesConstants, customerConstants, companyConstants } from '@constants/admin/';
import {
  BLANK_GUID,
} from '../../lib/simpletools';
import { BinderStatus } from '@constants/common.constants';

export var REDUCE_INITIAL = {
}

export var CUSTOMER_INITIAL = {
  loading: false,
  processing: false,
  loadingFiles: false,
}

let userCache = JSON.parse(localStorage.getItem(window.athenaAppID))
const initialState = userCache ?
  Object.assign({}, {customer: {}, boards: {}, currentBoard: {}, allBoards: [], bindersCurrent: {}, bindersPrevious: {}, templates: {}}, REDUCE_INITIAL)
  :
  Object.assign({}, {customer: {}, boards: {}, currentBoard: {}, allBoards: [], bindersCurrent: {}, bindersPrevious: {}, templates: {}}, REDUCE_INITIAL)

export var ATTENDEE_INITIAL_STATE = {
  id:"",
  loading: true,
  error: '',

  firstName: '',
  lastName: '',
  settings: null,
  boardId: '',
  imageId: '',
  userId: '',
  binderId: '',
  isUnavailableToUser: true,
};

export var RECIPIENT_INITIAL_STATE = {
  id:"",
  loading: true,
  error: '',

  firstName: '',
  lastName: '',
  settings: null,
  boardId: '',
  imageId: '',
  userId: '',
  binderId: '',
  isUnavailableToUser: true,
  isArchiveViewer: false,
};

export var BOARD_INITIAL_STATE = {
  id:"",
  loading: true,
  error: '',

  name: '',
  settings: null,
  address: '',
  countryBlacklist: [],
  calendarId: "",
  calendar: [],
  recipient: [],
  attendee: [],
  invitee: [],
  usages: null,
  imageId: '',
  customerId: '',
  memberIds: null,
  isSuspended: false,
  isComplete: false,
  selectedPreviousMoveMechanism: 3,
  endOfBoardYear: '',
  endOfFinancialYear: '',
  numberOfDaysPrevious: 60,
  isDeleted: false,
}

function cmpCalendarItem(a, b){
  var dateA=new Date(a.startTime), dateB=new Date(b.startTime)
  return dateA-dateB //sort by date ascending
}

function checkExists(state, boardId){
  if(state.boards === undefined)
    state.boards = {}
  if(state.boards[boardId] === undefined){
    state.boards[boardId] = Object.assign({}, BOARD_INITIAL_STATE)
    state.boards[boardId].id = boardId
    state.boards[boardId].loading = false
  }

  try { state.boards = { ...state.boards }; } catch {}

  return state
}

function GetBoardFilesList(state, boardId, customerId){
  if(state.boardFileList === undefined)
    state.boardFileList = {};
  if(state.boardFileList[boardId] === undefined)
    state.boardFileList[boardId] = [];

  if(customerId !== "" && state.customer[customerId] !== undefined){
    state.customer[customerId].loadingFiles = true
    state.customer[customerId].loading = true
  }
  return state;
}

function AddBoardFilesList(state, items, customerId){
  if(state.boardFileList === undefined)
    state.boardFileList = {};
  if(items.fileIds !== undefined)
    state.boardFileList[items.boardId] = items.fileIds;
  if(items.files !== undefined){
    var fileIds = [];
    items.files.forEach(function(o){
      fileIds.push(o.id);
    });
    state.boardFileList[items.boardId] = fileIds;
  }
  if(customerId !== "" && state.customer[customerId] !== undefined){
    state.customer[customerId].loadingFiles = false
    state.customer[customerId].loading = false
  }
  return state;
}

function CheckFile(state, fileId, boardId){
  if(state.boardFileList !== undefined){
    if(state.boardFileList[boardId] !== undefined){
      var p = state.boardFileList[boardId].indexOf(fileId);
      if(p === -1){
        state.boardFileList[boardId].push(fileId);
      }
    }
  }
  return state;
}

function DeleteFile(state, fileId, boardId){
  if(state.boardFileList !== undefined){
    if(state.boardFileList[boardId] !== undefined){
      var p = state.boardFileList[boardId].indexOf(fileId);
      if(p !== -1){
        state.boardFileList[boardId].splice(p, 1);
      }
    }
  }
  return state;
}

function UpdateCalendar(state, boardId, list, customerId){
  //sort by date
  list.sort(cmpCalendarItem);
  //group by same date
  state.calendar = {};
  //state.calendarItems = {};
  list.forEach(function(item) {
    var eventDate = new Date(item.startTime);
    var index = eventDate.getFullYear()+"-"+(eventDate.getMonth()+1)+"-"+eventDate.getDate();
    if(index in state.calendar){
      state.calendar[index].push(item);
    }else{
      state.calendar[index] = [item];
    }
    //state.calendarItems[item.id] = [item];

  });

  if(state.boards[boardId] !== undefined && state.boards[boardId].calendar !== undefined){
    var calendar = state.boards[boardId].calendar
    list.forEach(function(item) {
      if(calendar.find(o => o.id === item.id) === undefined)
        calendar.push(item)
    })
  }

  if(customerId !== "" && state.customer[customerId] !== undefined){
    state.customer[customerId].loading = false
  }

  return state;
}

function DeleteFileFromBoard(state, fileitem){
  for(var x=0; x<state.boardFileList[fileitem.boardId].length; x++){
    if(state.boardFileList[fileitem.boardId][x] === fileitem.fileId){
      state.boardFileList[fileitem.boardId].splice(x, 1);
      break;
    }
  }
  return state;
}

function AddFileFromBoard(state, fileitem){
  if(state.boardFileList[fileitem.boardId] !== undefined){
    if(state.boardFileList[fileitem.boardId].findIndex(o => o.fileId === fileitem.fileId) === -1){
      state.boardFileList[fileitem.boardId].push(fileitem.fileId)
    }
  }

  return state
}

function setBoardDetails(state, boardId){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = true;
  state.boards[boardId].id = boardId;

  try { state.boards = { ...state.boards }; } catch {}

  return state;
}

function getBoardsDetails(state, boards){
  boards.forEach(b => {
    getBoardDetails(state, b)
  })

  return state
}


function updateBoardVisibility(state, board) {
  state = checkExists(state, board.id);

  if (board.hasOwnProperty('isVisibleToAdminsWithoutAccess'))
    state.boards[board.id].isVisibleToAdminsWithoutAccess = board.isVisibleToAdminsWithoutAccess

  try { state.boards = { ...state.boards }; } catch {}

  return state;
}

function getBoardDetails(state, board){
  state = checkExists(state, board.id)
  
  if (state.allBoards) {
    var bIndex = state.allBoards.findIndex(b => b.id == board.id);
    if (bIndex > -1) {
      state.allBoards[bIndex] = { ...board };
    } else {
      state.allBoards.push({ ...board });
    }
  } else {
    state.allBoards = [{ ...board }];
  }

  if(board.hasOwnProperty('name'))
    state.boards[board.id].name = board.name
  if(board.hasOwnProperty('boardSettings'))
    state.boards[board.id].settings = Object.assign({}, board.boardSettings)
  if(board.hasOwnProperty('address'))
    state.boards[board.id].address = board.address
  if(board.hasOwnProperty('countryBlacklist'))
    state.boards[board.id].countryBlacklist = board.countryBlacklist
  if(board.hasOwnProperty('calendar'))
    state.boards[board.id].calendar = board.calendar
  if(board.hasOwnProperty('calendarId'))
    state.boards[board.id].calendarId = board.calendarId
  if(board.hasOwnProperty('recipientIds'))
    state.boards[board.id].recipientIds = board.recipientIds
  if(board.hasOwnProperty('attendeeIds'))
    state.boards[board.id].attendeeIds = board.attendeeIds
  if(board.hasOwnProperty('InviteeIds'))
    state.boards[board.id].InviteeIds = board.InviteeIds
  if(board.hasOwnProperty('imageId'))
    if(board.imageId === BLANK_GUID)
      state.boards[board.id].imageId = ""
    else
      state.boards[board.id].imageId = board.imageId
  if(board.hasOwnProperty('customerId'))
    state.boards[board.id].customerId = board.customerId
  if(board.hasOwnProperty('isSuspended'))
    state.boards[board.id].isSuspended = board.isSuspended
  if(board.hasOwnProperty('isSample'))
    state.boards[board.id].isSample = board.isSample;

  if(board.hasOwnProperty('selectedPreviousMoveMechanism'))
    state.boards[board.id].selectedPreviousMoveMechanism = board.selectedPreviousMoveMechanism
  if(board.hasOwnProperty('endOfBoardYear'))
    state.boards[board.id].endOfBoardYear = board.endOfBoardYear
  if(board.hasOwnProperty('endOfFinancialYear'))
    state.boards[board.id].endOfFinancialYear = board.endOfFinancialYear
  if(board.hasOwnProperty('numberOfDaysPrevious'))
    state.boards[board.id].numberOfDaysPrevious = board.numberOfDaysPrevious
  if(board.hasOwnProperty('isDeleted'))
    state.boards[board.id].isDeleted = board.isDeleted
  if(board.hasOwnProperty('usages'))
    state.boards[board.id].usages = board.usages

  if(board.hasOwnProperty('isVisibleToAdminsWithoutAccess'))
    state.boards[board.id].isVisibleToAdminsWithoutAccess = board.isVisibleToAdminsWithoutAccess


  state.boards[board.id].loading = false

  try { state.boards = { ...state.boards }; } catch {}

  if(state.customer[board.customerId] !== undefined && state.customer[board.customerId].membership !== undefined){
    if(state.customer[board.customerId].membership.findIndex(o => o === board.id) === -1)
      state.customer[board.customerId].membership.push(board.id)
    // if(state.membership.findIndex(o => o === board.id) === -1)
    //   state.membership.push(board.id)
  }

  return state
}

function setBoardSetting(state, items){
  items.forEach((item)=>{
    if(state.boards[item.boardId] !== undefined){
      item.id = item.boardId
      state = getBoardDetails(state, item)
    }
  })
  try { state.boards = { ...state.boards }; } catch {}

  return state
}

function DeleteUser(state, userId, customerId){
  var membership = null
  if(state.customer[customerId] !== null && state.customer[customerId] !== undefined && state.customer[customerId].membership !== undefined){
    for(var x=0; x<state.customer[customerId].membership.length; x++){
      var boardId = state.customer[customerId].membership[x];
      if(state.boards !== undefined)
        if(state.boards[boardId] !== undefined){
          if(state.boards[boardId].memberIds !== null){
            var i = state.boards[boardId].memberIds.indexOf(o => o.userId === userId);
            if(i){
              state.boards[boardId].memberIds.splice(i,1);
            }
          }
        }
    }

    //state.membership = state.customer[customerId].membership
  }
  
  try { state.boards = { ...state.boards }; } catch {}

  if(customerId !== "" && state.customer[customerId] !== undefined){
    state.customer[customerId].loading = true
  }

  return state;
}

function setBoardMembers(state, boardId, customerId){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = true;
  state.boards[boardId].id = boardId;
  state.boards[boardId].memberIds = [];

  try { state.boards = { ...state.boards }; } catch {}

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loading = true
  return state;
}

function updateBoardMemberships(state, memberships = []) {
  memberships.forEach(m => {
    checkExists(state, m.boardId);
    if (!state.boards[m.boardId].memberIds) {
      state.boards[m.boardId].memberIds = [m.userId];
    } else if (!state.boards[m.boardId].memberIds.includes(m.userId)) {
      state.boards[m.boardId].memberIds.push(m.userId);
      state.boards[m.boardId] = { ...state.boards[m.boardId] };
    }
  });
  try { state.boards = { ...state.boards }; } catch {}

  return state;
}

function getBoardMembers(state, boardId, memberlist, customerId, loading){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = false;
  state.boards[boardId].memberIds = memberlist;
  state.boards[boardId] = { ...state.boards[boardId] };
  try { state.boards = { ...state.boards }; } catch {}
  
  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loading = loading

  return state;
}

function addBoardMembers(state, payload){
  var boardId = payload.boardId;
  state = checkExists(state, boardId)
  if(state.boards[boardId].memberIds === null)
    state.boards[boardId].memberIds = [];
  state.boards[boardId].memberIds.push(payload);
  try { state.boards = { ...state.boards }; } catch {}

  return state;
}

function getBoardGroupMembers(state, boardslist){
  boardslist.forEach(function(item){
    var boardId = item.boardId;
    state = checkExists(state, boardId)
    if(state.boards[boardId].memberIds === null)
      state.boards[boardId].memberIds = [];
    state.boards[boardId].memberIds.push(item);
  })
  try { state.boards = { ...state.boards }; } catch {}

  return state;
}

function removeBoardMembers(state, boardId, membershipId, userId){
  state = checkExists(state, boardId)

  if(state.boards[boardId].memberIds === null)
    state.boards[boardId].memberIds = [];

  const index = state.boards[boardId].memberIds.map(e => e.id).indexOf(membershipId);
  if(index !== -1){
    state.boards[boardId].memberIds.splice(index, 1);
  }

  if(userId !== ""){
    if(state.Attendees !== undefined){
      var attendees = {};
      for(var key in state.Attendees){
        if(state.Attendees[key].userId !== userId)
          attendees[key] = state.Attendees[key];
      }
      state.Attendees = attendees;
    }

    if(state.Recipients !== undefined){
      var recipients = {};
      for(var key in state.Recipients){
        if(state.Recipients[key].userId !== userId)
          recipients[key] = state.Recipients[key];
      }
      state.Recipients = recipients;
    }
  }
  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function errorBoardDetails(state, boardId, error){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = false;
  state.boards[boardId].error = error;
  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function deleteBoardDetails(state, id){
  let customerId = ""
  if(state.hasOwnProperty('boards')){
    if(state.boards[id] !== undefined){
      customerId = state.boards[id].customerId
      delete state.boards[id];
    }
  }
  if(state.hasOwnProperty('allBoards')){
    const f = state.allBoards.findIndex(o => o.id === id)
    if(f !== -1){
      state.allBoards.splice(f, 1)
    }
  }
  if(customerId !== "" && state.customer[customerId] !== undefined && state.customer[customerId].membership !== undefined){
    const p = state.customer[customerId].membership.findIndex(o => o === id)
    if(p !== -1) state.customer[customerId].membership.splice(p, 1)
    //state.membership = state.customer[customerId].membership
  }

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loading = false

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function SetAttendee(state, id){
  if(id === "") return state;
  if(!state.hasOwnProperty('Attendees'))
    state.Attendees = {};
  if(state.Attendees[id] === undefined)
    state.Attendees[id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
  state.Attendees[id].id = id;
  state.Attendees[id].loading = true;
  state.Attendees[id].error = '';

  return state;
}

function UpdateAttendee(state, item){
  if(!state.hasOwnProperty('Attendees')){
    state.Attendees = {};
  }
  if(item.id === "") return state;
  if(state.Attendees[item.id] === undefined){
    state.Attendees[item.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
    state.Attendees[item.id].id = item.id;
  }
  state.Attendees[item.id].loading = false;
  Object.keys(item).map(e => state.Attendees[item.id][e] = item[e]);
  if(state.Attendees[item.id].settings === undefined) state.Attendees[item.id].settings = null;
  return state;
}

function UpdatePopulateAttendee(state, binderId, item){
  item.forEach(function(obj){
    obj.binderId = binderId;
    state = UpdateAttendee(state, obj)
  })
  return state;
}

function ErrorAttendee(state, id, error){
  if(!state.hasOwnProperty('Attendees')){
    state.Attendees = {};
    state.Attendees[id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
    state.Attendees[id].id = id;
  }
  state.Attendees[id].error = error;

  return state;
}

function DeleteAttendee(state, id){
  if(state.hasOwnProperty('Attendees')){
    delete state.Attendees[id];
  }
  return state;
}

function SetRecipient(state, id, error){
  if(!state.hasOwnProperty('Recipients'))
    state.Recipients = {};
  if(id === "") return state;
  if(state.Recipients[id] === undefined)
    state.Recipients[id] = Object.assign({}, RECIPIENT_INITIAL_STATE);
  state.Recipients[id].id = id;
  state.Recipients[id].loading = true;
  state.Recipients[id].error = '';
  return state;
}

function UpdateRecipient(state, item){
  if(!state.hasOwnProperty('Recipients')){
    state.Recipients = {};
  }
  if(item.id === "") return state;
  if(state.Recipients[item.id] === undefined){
    state.Recipients[item.id] = Object.assign({}, RECIPIENT_INITIAL_STATE);
    state.Recipients[item.id].id = item.id;
  }
  state.Recipients[item.id].loading = false;
  Object.keys(item).map(e => state.Recipients[item.id][e] = item[e]);
  if(state.Recipients[item.id].settings === undefined) state.Recipients[item.id].settings = '';

  return state;
}

function UpdatePopulateRecipient(state, binderId, item){
  item.forEach(function(obj){
    obj.binderId = binderId;
    state = UpdateRecipient(state, obj)
  })
  return state;
}

function ErrorRecipient(state, id, error){
  if(!state.hasOwnProperty('Recipients')){
    state.Recipients = {};
    state.Recipients[id] = Object.assign({}, RECIPIENT_INITIAL_STATE);
    state.Recipients[id].id = id;
  }
  state.Recipients[id].error = error;

  return state;
}

function DeleteRecipient(state, id){
  if(state.hasOwnProperty('Recipients')){
    delete state.Recipients[id];
  }
  return state;
}

function SetInvitee(state, id, error){
  if(!state.hasOwnProperty('Invitees'))
    state.Invitees = {};
  if(state.Invitees[id] === undefined)
    state.Invitees[id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
  state.Invitees[id].id = id;
  state.Invitees[id].loading = true;
  state.Invitees[id].error = '';
  return state;
}

function UpdateInvitee(state, item){
  if(!state.hasOwnProperty('Invitees')){
    state.Invitees = {};
    state.Invitees[item.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
    state.Invitees[item.id].id = item.id;
  }
  if(state.Invitees[item.id] === undefined)
    state.Invitees[item.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);

  state.Invitees[item.id].loading = false;
  Object.keys(item).map(e => state.Invitees[item.id][e] = item[e]);

  return state;
}

function ErrorInvitee(state, id, error){
  if(!state.hasOwnProperty('Invitees')){
    state.Invitees = {};
    state.Invitees[id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
    state.Invitees[id].id = id;
  }
  state.Invitees[id].error = error;

  return state;
}

function DeleteInvitee(state, id){
  if(state.hasOwnProperty('Invitees')){
    delete state.Invitees[id];
  }
  return state;
}

function RequestDrafts(state, boardId){
  if(boardId === undefined || boardId === "") return state;
  state = checkExists(state, boardId)

  if(state.boards[boardId].drafts === undefined)
    state.boards[boardId].drafts = null;
  state.boards[boardId].draftsLoading = true;

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function PopulateDrafts(state, boardId, payload){
  state.boards[boardId].draftsLoading = false;
  state.boards[boardId].drafts = [];
  payload.forEach(function(obj){
    state.boards[boardId].drafts.push(obj);
  });
  try { state.boards = { ...state.boards }; } catch {}

  return state
}

function FailedDrafts(state, boardId, error){
  if(boardId === "") return state
  state.boards[boardId].error = error;
  state.boards[boardId].draftsLoading = false;

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function RequestMinutes(state, boardId){
  if(boardId === undefined || boardId === "") return state;
  state = checkExists(state, boardId)

  if(state.boards[boardId].minutes === undefined)
    state.boards[boardId].minutes = null;
  state.boards[boardId].minutesLoading = true;

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function PopulateMinutes(state, boardId, payload){
  state.boards[boardId].minutesLoading = false;
  state.boards[boardId].minutes = [];
  payload.forEach(function(obj){
    state.boards[boardId].minutes.push(obj.id);
  });

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function FailedMinutes(state, boardId, error){
  if(boardId === undefined || boardId === "") return state
  state.boards[boardId].error = error;
  state.boards[boardId].minutesLoading = false;

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function PopulateCachedMinutes(state, boardId, item){
  if(boardId === undefined || boardId === "") return state;
  state = checkExists(state, boardId)

  state.boards[boardId].minutesDraft = [];
  state.boards[boardId].minutesTemplatesDraft = [];

  try {
    item.forEach(function (obj) {
      if (obj.minutesId.includes('draft')) {
        state.boards[boardId].minutesDraft.push(obj.minutesId);
      }
      if (obj.minutesId.includes('template')) {
        state.boards[boardId].minutesTemplatesDraft.push(obj.minutesId);
      }
    });
  } catch { }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function DeleteCachedMinutes(state, boardId, id){
  if(state.boards[boardId].minutesDraft !== undefined){
    var pos = state.boards[boardId].minutesDraft.indexOf(id);
    if(pos !== -1){
      state.boards[boardId].minutesDraft.splice(pos , 1);
    }
  }

  if(state.boards[boardId].minutesTemplatesDraft !== undefined){
    var pos = state.boards[boardId].minutesTemplatesDraft.indexOf(id);
    if(pos !== -1){
      state.boards[boardId].minutesTemplatesDraft.splice(pos , 1);
    }
  }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function DeleteMinute(state, minuteId, boardId){
  if(state.boards[boardId].minutes !== undefined){
    var pos = state.boards[boardId].minutes.indexOf(minuteId);
    if(pos !== -1){
      state.boards[boardId].minutes.splice(pos , 1);
    }
  }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function PopulateCachedBinder(state, item, boardId){
  if(state.bindersDraft === undefined)
    state.bindersDraft = {};
  if(state.templatesDraft === undefined)
    state.templatesDraft = {};
  var firstDraft = true, firstTemplate = true;
  item.forEach(function(obj){
    if(obj.binderId.includes('draft')){
      if(firstDraft){
        state.bindersDraft[obj.boardId] = [];
        firstDraft = false;
      }
      state.bindersDraft[obj.boardId].push(obj.binderId);
    }
    if(obj.binderId.includes('template')){
      if(firstTemplate){
        state.templatesDraft[obj.boardId] = [];
        firstTemplate = false;
      }
      state.templatesDraft[obj.boardId].push(obj.binderId);
    }
  });

  return state;
}

function DeleteCachedBinder(state, boardId, binderId){
  if(state.bindersDraft !== undefined){
    if(state.bindersDraft[boardId] !== undefined){
      var pos = state.bindersDraft[boardId].indexOf(binderId);
      if(pos !== -1){
        state.bindersDraft[boardId].splice(pos , 1);
      }
    }
  }

  if(state.templatesDraft !== undefined){
    if(state.templatesDraft[boardId] !== undefined){
      var pos = state.templatesDraft[boardId].indexOf(binderId);
      if(pos !== -1){
        state.templatesDraft[boardId].splice(pos , 1);
      }
    }
  }

  return state;
}

function renameBinderCached(state, from, to, boardId){
  if(state.bindersDraft === undefined)
    state.bindersDraft = {};

  if(state.bindersDraft[boardId] !== undefined){
    var pos = state.bindersDraft[boardId].indexOf(from);
    if(pos !== -1){
      state.bindersDraft[boardId].splice(pos , 1);
    }

    //state.bindersDraft[boardId].push(to);
  }

  return state;
}

function CreateUnpublishedBinderState(state, boardId) {
  if (!state.bindersUnpublished) {
    state.bindersUnpublished = {};
  }
  if (!state.bindersUnpublished[boardId]) {
    state.bindersUnpublished[boardId] = [];
  }
  if (!state.bindersCurrent[boardId]) {
    state.bindersCurrent[boardId] = [];
  }
  
  return state;
}

function ResetBinder(state, item, boardId, customerId){
  //state.bindersCurrent = {};
  //state.bindersPrevious = {};
  //state.bindersArchive = {};
  //state.bindersUnpublished = {};
  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loadingBinders = true

  return state;
}

function PopulateAllBinderAndTemplate(state, items, customerId){
  if(state.customer[customerId] !== undefined){
    state.customer[customerId].loadingAllBindersTemplates = false
  }
  items.forEach(item => {
    if(item.binders){
      state = PopulateBinder(state, item.binders, item.boardId, customerId)
    }
    if(item.templates){
      state = PopulateTemplates(state, item.templates, item.boardId, customerId)
    }
  })
  return state;
}

function PopulateBinder(state, item, boardId, customerId){
  if(state.bindersCurrent === undefined)
    state.bindersCurrent = {};
  state.bindersCurrent[boardId] = [];

  if (item && item.Current) {
    item.Current.forEach(function (obj) {
      if (state.bindersCurrent[boardId].indexOf(obj.id) === -1)
        state.bindersCurrent[boardId].push(obj.id);
      state = PopulateBinderItem(state, obj);
    });
  }

  if(state.bindersPrevious === undefined)
    state.bindersPrevious = {};
  state.bindersPrevious[boardId] = [];

  if (item && item.Previous) {
    item.Previous.forEach(function (obj) {
      if (state.bindersPrevious[boardId].indexOf(obj.id) === -1)
        state.bindersPrevious[boardId].push(obj.id);
      state = PopulateBinderItem(state, obj);
    });
  }

  /*if(state.bindersArchive === undefined)
    state.bindersArchive = {};
  if(state.bindersArchive[boardId] === undefined)
    state.bindersArchive[boardId] = [];
  state.bindersArchive[boardId] = [];
  item.Archive.forEach(function(obj){
    state.bindersArchive[boardId].push(obj.id);
    state = PopulateBinderItem(state,obj);
  });*/

  if(state.bindersUnpublished === undefined)
    state.bindersUnpublished = {};
  if(state.bindersUnpublished[boardId] === undefined)
    state.bindersUnpublished[boardId] = [];
  state.bindersUnpublished[boardId] = [];
  if (item && item.Unpublished) {
    item.Unpublished.forEach(function (obj) {
      if (state.bindersUnpublished[boardId].indexOf(obj.id) === -1)
        state.bindersUnpublished[boardId].push(obj.id);
      state = PopulateBinderItem(state, obj);
    });
  }

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loadingBinders = false

  return state;
}

function PopulateTemplates(state, item, boardId, customerId){
  if(state.templates === undefined)
    state.templates = {};
  if(state.templates[boardId] === undefined)
    state.templates[boardId] = [];
  item.forEach(function(obj){
    if(state.templates[boardId].indexOf(obj.id) === -1)
      state.templates[boardId].push(obj.id);
  });

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loadingTemplates = false
  state.latestAction = new Date()
  return state;
}

function PopulateBinderItem(state, item){
  if(item.hasOwnProperty('attendees')){
    if(!state.hasOwnProperty('Attendees')){
      state.Attendees = {};
    }
    item.attendees.forEach(function(object){
      state.Attendees[object.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
      state.Attendees[object.id].id = object.id;
      state.Attendees[object.id].loading = false;
      state.Attendees[object.id].binderId = item.id;
      Object.keys(object).map(e => state.Attendees[object.id][e] = object[e]);
    });
  }
  if(item.hasOwnProperty('invitees')){
    if(!state.hasOwnProperty('Invitees')){
      state.Invitees = {};
    }
    item.invitees.forEach(function(object){
      state.Invitees[object.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
      state.Invitees[object.id].id = object.id;
      state.Invitees[object.id].loading = false;
      state.Invitees[object.id].binderId = item.id;

      Object.keys(object).map(e => state.Invitees[object.id][e] = object[e]);
    });
  }
  if(item.hasOwnProperty('recipients')){
    if(!state.hasOwnProperty('Recipients')){
      state.Recipients = {};
    }
    item.recipients.forEach(function(object){
      state.Recipients[object.id] = Object.assign({}, RECIPIENT_INITIAL_STATE);
      state.Recipients[object.id].id = object.id;
      state.Recipients[object.id].loading = false;
      state.Recipients[object.id].binderId = item.id;

      Object.keys(object).map(e => state.Recipients[object.id][e] = object[e]);
    });
  }
  if(item.boardId !== undefined && item.boardId !== "" && !item.isSample){
    switch(item.binderStatus){
      case BinderStatus.unpublished:
        if(state.bindersUnpublished === undefined)
          state.bindersUnpublished = {};
        if(state.bindersUnpublished[item.boardId] === undefined)
          state.bindersUnpublished[item.boardId] = [];
        var i = state.bindersUnpublished[item.boardId].find(o => o === item.id);
        if(!i){
          state.bindersUnpublished[item.boardId].push(item.id);
        }
        break;
      case BinderStatus.current:
        if(state.bindersCurrent === undefined)
          state.bindersCurrent = {};
        if(state.bindersCurrent[item.boardId] === undefined)
          state.bindersCurrent[item.boardId] = [];
        var i = state.bindersCurrent[item.boardId].find(o => o === item.id);
        if(!i){
          state.bindersCurrent[item.boardId].push(item.id);
        }

        if(state.bindersUnpublished !== undefined && state.bindersUnpublished[item.boardId] !== undefined){
          var index = state.bindersUnpublished[item.boardId].findIndex(o => o === item.id);
          if(index !== -1) state.bindersUnpublished[item.boardId].splice(index,1)
        }
        break;
      case BinderStatus.previous:
        if(state.bindersPrevious === undefined)
          state.bindersPrevious = {};
        if(state.bindersPrevious[item.boardId] === undefined)
          state.bindersPrevious[item.boardId] = [];
        var i = state.bindersPrevious[item.boardId].find(o => o === item.id);
        if(!i){
          state.bindersPrevious[item.boardId].push(item.id);
        }
        break;
      case BinderStatus.archive:
        if(state.bindersArchive === undefined)
          state.bindersArchive = {};
        if(state.bindersArchive[item.boardId] === undefined)
          state.bindersArchive[item.boardId] = [];
        var i = state.bindersArchive[item.boardId].find(o => o === item.id);
        if(!i){
          state.bindersArchive[item.boardId].push(item.id);
        }
        break;
    }
  }

  return state;
}

function PopulateTemplate(state, item){
  if(item.hasOwnProperty('attendees')){
    if(!state.hasOwnProperty('Attendees')){
      state.Attendees = {};
    }
    item.attendees.forEach(function(object){
      state.Attendees[object.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
      state.Attendees[object.id].id = object.id;
      state.Attendees[object.id].loading = false;
      state.Attendees[object.id].binderId = item.id;
      Object.keys(object).map(e => state.Attendees[object.id][e] = object[e]);
    });
  }
  if(item.hasOwnProperty('invitees')){
    if(!state.hasOwnProperty('Invitees')){
      state.Invitees = {};
    }
    item.invitees.forEach(function(object){
      state.Invitees[object.id] = Object.assign({}, ATTENDEE_INITIAL_STATE);
      state.Invitees[object.id].id = object.id;
      state.Invitees[object.id].loading = false;
      state.Invitees[object.id].binderId = item.id;

      Object.keys(object).map(e => state.Invitees[object.id][e] = object[e]);
    });
  }
  if(item.hasOwnProperty('recipients')){
    if(!state.hasOwnProperty('Recipients')){
      state.Recipients = {};
    }
    item.recipients.forEach(function(object){
      state.Recipients[object.id] = Object.assign({}, RECIPIENT_INITIAL_STATE);
      state.Recipients[object.id].id = object.id;
      state.Recipients[object.id].loading = false;
      state.Recipients[object.id].binderId = item.id;

      Object.keys(object).map(e => state.Recipients[object.id][e] = object[e]);
    });
  }
  if(item.boardId !== undefined && item.boardId !== ""){
    if(state.templates === undefined)
      state.templates = {};
    if(state.templates[item.boardId] === undefined)
      state.templates[item.boardId] = [];
    var i = state.templates[item.boardId].find(o => o === item.id);
    if(!i){
      state.templates[item.boardId].push(item.id);
    }
  }

  return state;
}

function addTemplates(state, items, boardId){
  if(state.templates === undefined)
    state.templates = {};
  if(state.templates[boardId] === undefined)
    state.templates[boardId] = [];
  state.templates[boardId] = items;
  return state;
}

function CheckMinutes(state, item){
  if(item.boardId !== undefined && item.boardId !== "" && state.boards[item.boardId] !== undefined){
    if(state.boards[item.boardId].minutes === undefined)
      state.boards[item.boardId].minutes = [];
    var i = state.boards[item.boardId].minutes.find(o => o === item.id);
    if(!i && !item.id.startsWith('draft-')){
      state.boards[item.boardId].minutes.push(item.id);
    }
  }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function setBoards(state, items){
  if(state.customer[items.id] === undefined){
    state.customer[items.id] = Object.assign({}, CUSTOMER_INITIAL);
    //state.customer[items.id].boards = {}
  }

  state.customer[items.id].membership = items.boardIds

  return state;
}

function PopulateUserList(state, items){
  if(state.customer[items.id] === undefined){
    state.customer[items.id] = Object.assign({}, CUSTOMER_INITIAL);
    //state.customer[items.id].boards = {}
  }

  var membership = [];
  for(var x=0; x<items.boards.length; x++){
    membership.push(items.boards[x].id);
    if(state.customerId === items.id)
      state = getBoardDetails(state, items.boards[x]);
  }

  state.customer[items.id].membership = [...membership];

  return state;
}

function DeleteBinder(state, binderId, boardId){
  if(state.bindersCurrent !== undefined){
    if(state.bindersCurrent[boardId] !== undefined){
      var index = state.bindersCurrent[boardId].indexOf(binderId)
      if(index !== -1) state.bindersCurrent[boardId].splice(index, 1);
    }
  }
  if(state.bindersPrevious !== undefined){
    if(state.bindersPrevious[boardId] !== undefined){
      var index = state.bindersPrevious[boardId].indexOf(binderId)
      if(index !== -1) state.bindersPrevious[boardId].splice(index, 1);
    }
  }
  if(state.bindersArchive !== undefined){
    if(state.bindersArchive[boardId] !== undefined){
      var index = state.bindersArchive[boardId].indexOf(binderId)
      if(index !== -1) state.bindersArchive[boardId].splice(index, 1);
    }
  }
  if(state.bindersUnpublished !== undefined){
    if(state.bindersUnpublished[boardId] !== undefined){
      var index = state.bindersUnpublished[boardId].indexOf(binderId)
      if(index !== -1) state.bindersUnpublished[boardId].splice(index, 1);
    }
  }

  return state;
}

function DeleteTemplate(state, binderId, boardId){
  if(state.templates !== undefined){
    if(state.templates[boardId] !== undefined){
      var index = state.templates[boardId].indexOf(binderId)
      if(index !== -1) state.templates[boardId].splice(index, 1);
    }
  }

  return state;
}

function MoveBindersInBoard(state, binderItem){
  if (binderItem.willMoveToPrevious) {
    try {
      state.bindersCurrent[binderItem.boardId] = state.bindersCurrent[binderItem.boardId].filter(item => item !== binderItem.id);
      state.bindersUnpublished[binderItem.boardId] = state.bindersUnpublished[binderItem.boardId].filter(item => item !== binderItem.id);
    } catch (e) {
      console.log('failed', e);
    }
  }
  if(binderItem.hasOwnProperty('from') && binderItem.hasOwnProperty('to') && binderItem.id !== ''){
    if(binderItem.from === BinderStatus.current)
      state.bindersCurrent[binderItem.boardId] = state.bindersCurrent[binderItem.boardId].filter(item => item !== binderItem.id);
    else if(binderItem.from === BinderStatus.previous)
      state.bindersPrevious[binderItem.boardId] = state.bindersPrevious[binderItem.boardId].filter(item => item !== binderItem.id);
    else if(binderItem.from === BinderStatus.archive)
      state.bindersArchive[binderItem.boardId] = state.bindersArchive[binderItem.boardId].filter(item => item !== binderItem.id);
    else if(binderItem.from === BinderStatus.unpublished)
      state.bindersUnpublished[binderItem.boardId] = state.bindersUnpublished[binderItem.boardId].filter(item => item !== binderItem.id);

    if(binderItem.to === BinderStatus.current){
      if(state.bindersCurrent[binderItem.boardId].indexOf(binderItem.id) === -1)
        state.bindersCurrent[binderItem.boardId].push(binderItem.id);
    }else if(binderItem.to === BinderStatus.previous){
      if(state.bindersPrevious[binderItem.boardId].indexOf(binderItem.id) === -1)
        state.bindersPrevious[binderItem.boardId].push(binderItem.id);
    }else if(binderItem.to === BinderStatus.archive){
      if(state.bindersArchive[binderItem.boardId].indexOf(binderItem.id) === -1)
        state.bindersArchive[binderItem.boardId].push(binderItem.id);
    }else if(binderItem.to === BinderStatus.unpublished){
      if(state.bindersUnpublished[binderItem.boardId].indexOf(binderItem.id) === -1)
        state.bindersUnpublished[binderItem.boardId].push(binderItem.id);
    }
  }
  return state;
}

function PopluateAndMove(state, binderItem){
  state = PopulateBinderItem(state, binderItem)
  state = MoveBindersInBoard(state, binderItem)
  return state;
}

function SetUsage(state, boardId){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = true;
  // state.boards[boardId].usages = null;
  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function UpdateUsage(state, boardId, data) {
  state = checkExists(state, boardId)
  state.boards[boardId].loading = false;
  if (!state.boards[boardId].usages) {
    state.boards[boardId].usages = [];
  }
  state.boards[boardId].usages = [...state.boards[boardId].usages, ...data];
  state.boards[boardId].usages = _.uniqBy(state.boards[boardId].usages, u => u.id);
  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function ErrorUsage(state, boardId, error){
  state = checkExists(state, boardId)
  state.boards[boardId].loading = true;
  state.boards[boardId].error = error;
  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function addCalendarItem(state, boardId, item){
  if(state.calendar === undefined) state.calendar = {}
  if(item.id === undefined || item.id === "") return state

  var eventDate = new Date(item.startTime);
  var index = eventDate.getFullYear()+"-"+(eventDate.getMonth()+1)+"-"+eventDate.getDate();
  if(index in state.calendar){
    state.calendar[index].push(item);
    state.calendar[index].sort(cmpCalendarItem)
  }else{
    state.calendar[index] = [item];
  }

  if(state.boards[boardId] !== undefined && state.boards[boardId].calendar !== undefined){
    var f = state.boards[boardId].calendar.findIndex(o => o.id === item.id)
    if(f === -1){
      state.boards[boardId].calendar.push(item)
    }else{
      state.boards[boardId].calendar[f] = item
    }
  }

  if(state.boards[boardId] !== undefined && state.boards[boardId].calendar !== undefined){
    var calendar = state.boards[boardId].calendar
    if(calendar.findIndex(o => o.id === item.id) === -1)
      calendar.push(item)
  }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function deleteCalendar(state, boardId, calendarId, customerId){
  if(state.boards[boardId] !== undefined && state.boards[boardId].calendar !== undefined){
    var calendar = state.boards[boardId].calendar
    const p = calendar.findIndex(o => o.id === calendarId)
    if(p !== -1)
      calendar.splice(p, 1)
  }

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].loading = false

  return state
}

function PopulateArchiveList(state, list, boardId, customerId){
  if(state.bindersArchive === undefined)
    state.bindersArchive = {};
  state.bindersArchive[boardId] = [];

  list.Archive.forEach(function(obj){
    state.bindersArchive[boardId].push(obj.id);
    state = PopulateBinderItem(state,obj);
  });

  if(customerId !== "" && state.customer[customerId] !== undefined)
    state.customer[customerId].bindersArchiveLoading = false

  return state;
}

function populateUserBinder(state, boardId, binderId, userId, item){
  if(item.attendees !== undefined){
    item.attendees.forEach(function(obj){
      obj.binderId = binderId;
      state = UpdateAttendee(state, obj)
    })
  }
  if(item.recipients !== undefined){
    item.recipients.forEach(function(obj){
      obj.binderId = binderId;
      state = UpdateRecipient(state, obj)
    })
  }
  if(item.invitees !== undefined){
    item.invitees.forEach(function(obj){
      obj.binderId = binderId;
      state = UpdateInvitee(state, obj)
    })
  }
  return state;
}

function populateBoards(state, doneItem, customerId){
  if(doneItem.boards !== undefined && customerId !== ""){
    if(state.customer[customerId] === undefined){
      state.customer[customerId] = Object.assign({}, CUSTOMER_INITIAL);
      //state.customer[customerId].boards = {}
    }
    state.customer[customerId].membership = [];
    for(var key in doneItem.boards){
      if(customerId === doneItem.boards[key].customerId)
        state.customer[customerId].membership.push(doneItem.boards[key].id);
      state = getBoardDetails(state, doneItem.boards[key]);
    }

    for(var key in doneItem.binders){
      state = PopulateBinderItem(state, doneItem.binders[key]);
    }
  }

  return state;
}

function updateBoardSettings(state, boardId, boardSettings) {
  if (state.boards && state.boards[boardId]) {
    try { state.boards = { ...state.boards }; } catch {}
    state.boards[boardId] = { ...state.boards[boardId] };
    state.boards[boardId].settings = { ...boardSettings };
  }

  try { state.boards = { ...state.boards }; } catch {}
  return state;
}

function updateCache(state, obj, customerId = ""){
  // if(customerId === "")
  //   customerId = state.customerId

  if(customerId !== ""){
    if(state.customer[customerId] === undefined){
      state.customer[customerId] = Object.assign({}, CUSTOMER_INITIAL)
      //state.customer[customerId].boards = {}
    }
    state.customer[customerId] = Object.assign({}, state.customer[customerId], obj)
  }

//TODO Remove
  //state = Object.assign({}, state, obj)

  return state
}

function boardCheck(state, boardId, deleteIds){
  if(state.boards[boardId] !== undefined)
    state.boards[boardId].deleteIds = deleteIds
  try { state.boards = { ...state.boards }; } catch {}
  return state
}

/*function resetData(state, customerId){
  //Save any old stuff
  if(state.customerId !== ""){
    if(state.customer[state.customerId] === undefined){
      state.customer[state.customerId] = Object.assign({}, CUSTOMER_INITIAL);
      //state.customer[state.customerId].boards = {}
    }

    if(state.customer[state.customerId] !== undefined){
      state.customer[state.customerId].processing = state.processing
      state.customer[state.customerId].loadingFiles = state.loadingFiles
      state.customer[state.customerId].membership = state.membership
    }
  }

  if(state.customer[customerId] !== undefined){
    state.processing = state.customer[customerId].processing
    state.loadingFiles = state.customer[customerId].loadingFiles
    state.loading = false
  }else{
    state.processing = false
    state.loadingFiles = false
    state.loading = false
  }
  //state.customerId = customerId
  return state
}*/

function basicUpdate(state, item){
  if(state.boards[item.id] === undefined) return state
  state.boards[item.id] = Object.assign({}, state.boards[item.id], item)

  try { state.boards = { ...state.boards }; } catch {}
  return state
}

function setCurrent(state, item){
  if(item.boardId !== undefined){
    state.currentBoard.id = item.boardId
  }
  if(item.boardName !== undefined){
    state.currentBoard.name = item.boardName
  }
  if(item.customerId !== undefined){
    state.currentBoard.customerId = item.customerId
  }
  return state
}

function setAllBoard(state, items){
  if(items.length > 0){
    state.allBoards = items
  }
  return state
}

function setCustomer(state, customerId, loading){
  if(state.customer[customerId] === undefined){
    state.customer[customerId] = Object.assign({}, CUSTOMER_INITIAL);
  }

  state.customer[customerId].loadingAllBindersTemplates = loading
  return state
}

export function board(state = initialState, action) {
  switch (action.type) {
    case boardConstants.GETMEMBERSHIP_REQUEST:
      return Object.assign({}, setBoardMembers(state, action.boardId, action.customerId), {latestAction: new Date()});
    case boardConstants.UPDATE_BOARD_MEMBERSHIPS:
      return Object.assign({}, updateBoardMemberships(state, action.payload));
    case boardConstants.GETMEMBERSHIP_UPDATE:
      return Object.assign({}, getBoardMembers(state, action.boardId, action.membership, action.customerId, true), {latestAction: new Date()});
    case boardConstants.GETMEMBERSHIP_SUCCESS:
      return Object.assign({}, getBoardMembers(state, action.boardId, action.membership, action.customerId, false), {latestAction: new Date()});
    case boardConstants.GETMEMBERSHIP_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, loading: false}, action.customerId))
    case boardConstants.SETGROUPMEMBERSHIP_REQUEST:
    return Object.assign({}, getBoardGroupMembers(state, action.payload), {latestAction: new Date()});
    case boardConstants.SETMEMBERSHIP_REQUEST:
      return Object.assign({}, updateCache(state, {latestAction: new Date()}))
    case boardConstants.SETMEMBERSHIP_SUCCESS:
      return Object.assign({}, state, addBoardMembers(state, action.payload), {latestAction: new Date()});
    case boardConstants.SETMEMBERSHIP_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))
    case boardConstants.DELETEMEMBERSHIP_REQUEST:
      return Object.assign({}, updateCache(state, {latestAction: new Date()}))
    case boardConstants.DELETEMEMBERSHIP_SUCCESS:
      return Object.assign({}, state, removeBoardMembers(state, action.boardId, action.membershipId, action.userId), {latestAction: new Date()});
    case boardConstants.DELETEMEMBERSHIP_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))
    case userConstants.GETALL_SUCCESS:
      return Object.assign({}, setBoards(state, action.items))
    case boardConstants.GETBOARD_REQUEST:
      return Object.assign({}, setBoardDetails(state, action.id),{latestAction: new Date()});
    case boardConstants.GETBOARD_SUCCESS:
      return Object.assign({}, getBoardDetails(state, action.board),{latestAction: new Date()});
    case boardConstants.GETBOARD_FAILURE:
      return Object.assign({}, errorBoardDetails(state, action.id, action.error), {error: action.error});
    case boardConstants.BOARD_GETBOARDS_REQUEST:
      return Object.assign({}, state, {latestAction: new Date()});
    case boardConstants.BOARD_GETBOARDS_SUCCESS:
      return Object.assign({}, getBoardsDetails(state, action.board),{latestAction: new Date()});
    case boardConstants.BOARD_GETBOARDS_FAILURE:
      return Object.assign({}, state, {error: action.error});
    case boardConstants.DELETEBOARD_REQUEST:
      return Object.assign({}, updateCache(state, { loadingDelete: true }, action.customerId))
    case boardConstants.DELETEBOARD_SUCCESS:
      return Object.assign({}, deleteBoardDetails(state, action.id), {latestAction: new Date() });
    case boardConstants.DELETEBOARD_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, loadingDelete: false}, action.customerId))
    case boardConstants.SETBOARD_REQUEST:
      return Object.assign({}, updateCache(state, {loading:true}, action.customerId))
    case boardConstants.CREATE_UNPUBLISHED_BINDER_STATE:
      return Object.assign({}, CreateUnpublishedBinderState(state, action.payload.boardId));
    case boardConstants.SETBOARD_SUCCESS:
      var board = action.board;
      if(board.boardId !== undefined && board.boardId !== "")
        board.id = board.boardId;
      return Object.assign({}, updateCache(getBoardDetails(state, board), {loading:false}, action.customerId), {latestAction: new Date()});
    case boardConstants.UPDATE_BOARD_VISIBILITY:
      return Object.assign({}, updateBoardVisibility(state, action.payload));
    case boardConstants.SETBOARD_FAILURE:
      return Object.assign({}, updateCache(state, {loading:false, error: action.error}, action.customerId))
    case boardConstants.BOARD_FILELIST_REQUEST:
      return Object.assign({}, GetBoardFilesList(state, action.boardId, action.customerId));
    case boardConstants.BOARD_FILELIST_SUCCESS:
      return Object.assign({}, AddBoardFilesList(state, action.items, action.customerId));
    case boardConstants.BOARD_FILELIST_FAILURE:
      return Object.assign({}, state, updateCache({error: action.error}, action.customerId));
    case boardConstants.BOARD_POPULATEFILELIST_REQUEST:
      return Object.assign({}, GetBoardFilesList(state, action.boardId, action.customerId));
    case boardConstants.BOARD_POPULATEFILELIST_SUCCESS:
      return Object.assign({}, AddBoardFilesList(state, action.items, action.customerId));
    case boardConstants.BOARD_POPULATEFILELIST_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case boardConstants.GET_CALENDAR_REQUEST:
      return Object.assign({}, updateCache(state, {loading:true}, action.customerId))
    case boardConstants.GET_CALENDAR_SUCCESS:
      return Object.assign({}, UpdateCalendar(state, action.boardId ,action.list, action.customerId));
    case boardConstants.GET_CALENDAR_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, loading:false}, action.customerId))
    case boardConstants.GET_CALENDARITEM_REQUEST:
      return Object.assign({}, state);
    case boardConstants.GET_CALENDARBINDER_SUCCESS:
    case boardConstants.GET_CALENDARITEM_SUCCESS:
      return Object.assign({}, addCalendarItem(state, action.boardId, action.payload));
    case boardConstants.GET_CALENDARITEM_FAILURE:
      return Object.assign({}, state);
    case boardConstants.DELETE_CALENDAR_REQUEST:
      return Object.assign({}, updateCache(state, { error: "", loading: true }, action.customerId))
    case boardConstants.DELETE_CALENDAR_SUCCESS:
      return Object.assign({}, deleteCalendar(state, action.boardId, action.calItemId, action.customerId))
    case boardConstants.DELETE_CALENDAR_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, loading: false}, action.customerId))
    case boardConstants.SET_CALENDAR_REQUEST:
      return Object.assign({}, updateCache(state, { error: "", loading: true }))
    case boardConstants.SET_CALENDAR_SUCCESS:
      return Object.assign({}, updateCache(addCalendarItem(state, action.boardId, action.newItem), action.customerId))
    case boardConstants.SET_CALENDAR_FAILURE:
      return Object.assign({}, updateCache(state, { loading: false, error: action.error}, action.customerId))
    case boardConstants.EDIT_CALENDAR_REQUEST:
      return Object.assign({}, updateCache(state, { error: "", loading: true }, action.customerId))
    case boardConstants.EDIT_CALENDAR_SUCCESS:
      return Object.assign({}, updateCache(state, { loading: false }, action.customerId))
    case boardConstants.EDIT_CALENDAR_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case boardConstants.BOARD_DELETEFILE_REQUEST:
      return Object.assign({}, DeleteFileFromBoard(state, action.fileitem));
    case boardConstants.BOARD_ADDFILE_REQUEST:
      return Object.assign({}, AddFileFromBoard(state, action.fileitem));

    case boardConstants.GET_BINDERS_REQUEST:
      return Object.assign({}, updateCache(state, { loading: true }, action.customerId))
    case boardConstants.GET_BINDERS_SUCCESS:
      return Object.assign({}, updateCache(state, { loading: false }, action.customerId))
    case boardConstants.GET_BINDERS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case boardConstants.POPULATE_BINDERS_REQUEST:
      return Object.assign({}, ResetBinder(state, action.boardId, action.customerId));
    case boardConstants.POPULATE_BINDERS_SUCCESS:
      return Object.assign({}, PopulateBinder(state, action.data, action.boardId, action.customerId),{latestAction: new Date()});
    case boardConstants.POPULATE_BINDERS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case boardConstants.POPULATE_TEMPLATES_REQUEST:
      return Object.assign({}, updateCache(state, {loadingTemplates: true}, action.customerId));
    case boardConstants.POPULATE_TEMPLATES_SUCCESS:
      return Object.assign({}, PopulateTemplates(state, action.data, action.boardId, action.customerId));
    case boardConstants.POPULATE_TEMPLATES_FAILURE:
      return Object.assign({}, updateCache(state, {loadingTemplates: false}, action.customerId));

    case boardConstants.BOARD_SETTINGS_UPDATE:
      return Object.assign({}, updateBoardSettings(state, action.payload.boardId, action.payload.boardSettings));

    case boardConstants.GET_BINDERS_CURRENT_REQUEST:
      return Object.assign({}, updateCache(state, {bindersCurrent: undefined }))
    case boardConstants.GET_BINDERS_CURRENT_SUCCESS:
      return Object.assign({}, updateCache(state, {bindersCurrent: action.list }))
    case boardConstants.GET_BINDERS_CURRENT_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))
    case boardConstants.GET_BINDERS_PREVIOUS_REQUEST:
      return Object.assign({}, updateCache(state, {bindersPrevious: undefined}))
    case boardConstants.GET_BINDERS_PREVIOUS_SUCCESS:
      return Object.assign({}, updateCache(state, {bindersPrevious: action.list}))
    case boardConstants.GET_BINDERS_PREVIOUS_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))
    case boardConstants.GET_BINDERS_ARCHIVE_REQUEST:
      return Object.assign({}, updateCache(state, { bindersArchiveLoading: true }, action.customerId))
    case boardConstants.GET_BINDERS_ARCHIVE_SUCCESS:
      return Object.assign({}, PopulateArchiveList(state, action.list, action.boardId, action.customerId));
    case boardConstants.GET_BINDERS_ARCHIVE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}, action.customerId))
    case boardConstants.GET_BINDERS_UNPUBLISHED_REQUEST:
      return Object.assign({}, updateCache(state, {bindersUnpublished: undefined}))
    case boardConstants.GET_BINDERS_UNPUBLISHED_SUCCESS:
      return Object.assign({}, updateCache(state, {bindersUnpublished: action.list}))
    case boardConstants.GET_BINDERS_UNPUBLISHED_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))
    case binderConstants.GET_BINDERCACHED_SUCCESS:
      return Object.assign({}, PopulateCachedBinder(state, action.item, action.boardId));
    case binderConstants.DELETE_BINDERCACHED_SUCCESS:
      return Object.assign({}, DeleteCachedBinder(state, action.boardId, action.id));

    case boardConstants.GET_BOARD_MINUTES_REQUEST:
      return Object.assign({}, RequestMinutes(state, action.boardId),{latestAction: new Date()});
    case boardConstants.GET_BOARD_MINUTES_SUCCESS:
      return Object.assign({}, PopulateMinutes(state, action.boardId, action.payload),{latestAction: new Date()});
    case boardConstants.GET_BOARD_MINUTES_FAILURE:
      return Object.assign({}, FailedMinutes(state, action.boardId, action.error),{latestAction: new Date()});
    case minutesConstants.GET_MINUTESCACHED_SUCCESS:
      return Object.assign({}, PopulateCachedMinutes(state, action.boardId, action.item),{latestAction: new Date()});
    case minutesConstants.DELETE_MINUTECACHED_SUCCESS:
      return Object.assign({}, DeleteCachedMinutes(state, action.boardId, action.id),{latestAction: new Date()});
    case minutesConstants.DELETE_MINUTECONTENT_SUCCESS:
      return Object.assign({}, DeleteMinute(state, action.minutesId, action.boardId),{latestAction: new Date()});
    case boardConstants.GET_BOARD_DRAFTS_REQUEST:
      return Object.assign({}, RequestDrafts(state, action.boardId),{latestAction: new Date()});
    case boardConstants.GET_BOARD_DRAFTS_SUCCESS:
      return Object.assign({}, PopulateDrafts(state, action.boardId, action.payload),{latestAction: new Date()});
    case boardConstants.GET_BOARD_DRAFTS_FAILURE:
      return Object.assign({}, FailedDrafts(state, action.boardId, action.error),{latestAction: new Date()});

    case binderConstants.DELETE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, DeleteBinder(state, action.binderId, action.boardId),{latestAction: new Date()});
    case binderConstants.DELETE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, DeleteTemplate(state, action.binderId, action.boardId),{latestAction: new Date()});

    case binderConstants.COPY_BINDERCONTENT_REQUEST:
      return Object.assign({}, updateCache(state, {loading: true}, action.customerId))
    case binderConstants.COPY_BINDERCONTENT_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false}, action.customerId))
    case binderConstants.COPY_BINDERCONTENT_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error, loading: false}, action.customerId))

    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS_DONOTHING:
      return Object.assign({}, state);
    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS:
      return Object.assign({}, PopluateAndMove(state, action.binderItem),{latestAction: new Date()});
    case binderConstants.UPDATE_BINDERSTATUS_SUCCESS_MOVE:
      return Object.assign({}, MoveBindersInBoard(state, action.binderItem),{latestAction: new Date()});

    case boardConstants.GET_TEMPLATES_REQUEST:
      return Object.assign({}, state);
    case boardConstants.GET_TEMPLATES_SUCCESS:
      return Object.assign({}, addTemplates(state, action.list, action.boardId),{latestAction: new Date()});
    case boardConstants.GET_TEMPLATES_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.error}))

    case boardConstants.GET_ATTENDEE_REQUEST:
      return Object.assign({}, SetAttendee(state, action.id));
    case boardConstants.GET_ATTENDEE_SUCCESS:
      return Object.assign({}, UpdateAttendee(state, action.item),{latestAction: new Date()});
    case boardConstants.GET_ATTENDEE_FAILURE:
      return Object.assign({}, ErrorAttendee(state, action.id, action.error));
    case boardConstants.DELETE_ATTENDEE_REQUEST:
      return Object.assign({}, DeleteAttendee(state, action.id));
    case boardConstants.DELETE_ATTENDEE_SUCCESS:
      return Object.assign({}, state);
    case boardConstants.DELETE_ATTENDEE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.id + ":" + action.error}))
    case boardConstants.POPULATE_ATTENDEE_REQUEST:
      return Object.assign({}, state);
    case boardConstants.POPULATE_ATTENDEE_SUCCESS:
      return Object.assign({}, UpdatePopulateAttendee(state, action.binderId, action.item),{latestAction: new Date()});
    case boardConstants.POPULATE_ATTENDEE_FAILURE:
      return Object.assign({}, state);

    case boardConstants.GET_RECIPIENT_REQUEST:
      return Object.assign({}, SetRecipient(state, action.id));
    case boardConstants.GET_RECIPIENT_SUCCESS:
      return Object.assign({}, UpdateRecipient(state, action.item),{latestAction: new Date()});
    case boardConstants.GET_RECIPIENT_FAILURE:
      return Object.assign({}, ErrorRecipient(state, action.id, action.error));
    case boardConstants.DELETE_RECIPIENT_REQUEST:
      return Object.assign({}, DeleteRecipient(state, action.id));
    case boardConstants.DELETE_RECIPIENT_SUCCESS:
      return Object.assign({}, state);
    case boardConstants.DELETE_RECIPIENT_FAILURE:
      return Object.assign({}, state, {error: action.id + ":" + action.error});
    case boardConstants.POPULATE_RECIPIENT_REQUEST:
      return Object.assign({}, state);
    case boardConstants.POPULATE_RECIPIENT_SUCCESS:
      return Object.assign({}, UpdatePopulateRecipient(state, action.binderId, action.item),{latestAction: new Date()});
    case boardConstants.POPULATE_RECIPIENT_FAILURE:
      return Object.assign({}, state);

    case boardConstants.GET_INVITEE_REQUEST:
      return Object.assign({}, SetInvitee(state, action.id));
    case boardConstants.GET_INVITEE_SUCCESS:
      return Object.assign({}, UpdateInvitee(state, action.item),{latestAction: new Date()});
    case boardConstants.GET_INVITEE_FAILURE:
      return Object.assign({}, ErrorInvitee(state, action.id, action.error));
    case boardConstants.DELETE_INVITEE_REQUEST:
      return Object.assign({}, DeleteInvitee(state, action.id));
    case boardConstants.DELETE_INVITEE_SUCCESS:
      return Object.assign({}, state);
    case boardConstants.DELETE_INVITEE_FAILURE:
      return Object.assign({}, updateCache(state, {error: action.id + ":" + action.error}))

    case boardConstants.ISDELETE_MEMBERSHIP_REQUEST:
      return Object.assign({}, boardCheck(state, action.boardId, null), {latestAction: new Date()})
    case boardConstants.ISDELETE_MEMBERSHIP_SUCCESS:
      return Object.assign({}, boardCheck(state, action.boardId, action.deleteIds), {latestAction: new Date()});
    case boardConstants.ISDELETE_MEMBERSHIP_FAILURE:
      return Object.assign({}, boardCheck(state, action.boardId, false), {latestAction: new Date()})

    case binderConstants.POPULATE_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.item));
    case binderConstants.POPULATE_BINDERCONTENT_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.item));
    case userConstants.POPULATEALL_USERS_SUCCESS:
      return Object.assign({}, updateCache(PopulateUserList(state, action.items), {loading: false}, action.customerId), {latestAction: new Date()});

    case binderConstants.UPDATE_TEMPLATECONTENT_SUCCESS:
    case binderConstants.NEW_TEMPLATECONTENT_SUCCESS:
      return Object.assign({}, PopulateTemplate(state, action.templateItem));

    case binderConstants.NEW_BINDERSTATUS_SUCCESS:
      return Object.assign({}, PopulateBinderItem(state, action.binderItem));

    case userConstants.GET_CUSTOMER_SERIALCARD_REQUEST:
      return Object.assign({}, updateCache(state, {recoveryCards: null}))
    case userConstants.GET_CUSTOMER_SERIALCARD_SUCCESS:
      return Object.assign({}, updateCache(state, {recoveryCards: action.payload}))
    case userConstants.GET_CUSTOMER_SERIALCARD_FAILURE:
      return Object.assign({}, updateCache(state, {loading: false, error: action.error}, action.customerId), {latestAction: new Date()})

    case fileConstants.UPDATE_FILE_REQUEST:
      return Object.assign({}, CheckFile(state, action.fileId, action.boardId), {loading: true});
    case fileConstants.DELETE_FILE_REQUEST:
      return Object.assign({}, DeleteFile(state, action.fileId, action.boardId), {loading: true});
    case fileConstants.DELETE_FILE_SUCCESS:
      return Object.assign({}, updateCache(state, {loading: false}, action.customerId))

    case boardConstants.PROCESSING_BINDER_REQUEST:
      return Object.assign({}, updateCache(state, {processing: true}), action.customerId)
    case boardConstants.PROCESSING_BINDER_SUCCESS:
      return Object.assign({}, updateCache(state, {processing: false}), action.customerId)
    case boardConstants.PROCESSING_BINDER_FAILURE:
      return Object.assign({}, updateCache(state, {processing: false}), action.customerId)

    case binderConstants.RENAME_BINDERCACHED_SUCCESS:
      return Object.assign({}, renameBinderCached(state, action.from, action.to, action.boardId));

    case userConstants.DELETE_SUCCESS:
      return Object.assign({}, DeleteUser(state, action.id, action.customerId));

    case boardConstants.GET_BOARD_USAGE_REQUEST:
      return Object.assign({}, SetUsage(state, action.boardId),{latestAction: new Date()});
    case boardConstants.GET_BOARD_USAGE_SUCCESS:
      return Object.assign({}, UpdateUsage(state, action.boardId, action.data),{latestAction: new Date()});
    case boardConstants.GET_BOARD_USAGE_FAILURE:
      return Object.assign({}, ErrorUsage(state, action.boardId, action.error), {latestAction: new Date()});

    case binderConstants.POPULATE_USERBINDERCONTENT_SUCCESS:
      return Object.assign({}, populateUserBinder(state, action.boardId, action.binderId, action.userId, action.item), {latestAction: new Date()});

    case binderConstants.COPY_BINDERCONTENT_REQUEST:
      return Object.assign({}, basicUpdate(state, {id: action.id, loading: true, currentToPrevious: null}))
    case binderConstants.COPY_BINDERCONTENT_SUCCESS:
      return Object.assign({}, basicUpdate(state, {id: action.id, loading: false, currentToPrevious: true}))
    case binderConstants.COPY_BINDERCONTENT_FAILURE:
      return Object.assign({}, basicUpdate(state, {id: action.id, loading: false, currentToPrevious: false}))

    case minutesConstants.GET_MINUTES_SUCCESS:
      return Object.assign({}, CheckMinutes(state, action.minutes));

    case companyConstants.AUDIT_POPULATEFILELIST_REQUEST:
      return Object.assign({}, updateCache(state, {auditMsg: "", auditLoading: true, auditError: ""}, action.customerId))
    case companyConstants.AUDIT_POPULATEFILELIST_STATUS:
      return Object.assign({}, updateCache(state, {auditMsg: action.message, auditLoading: true}, action.customerId))
    case companyConstants.AUDIT_POPULATEFILELIST_CANCEL:
      return Object.assign({}, updateCache(state, { auditMsg: "", auditLoading: false }, action.customerId))
    case companyConstants.AUDIT_POPULATEFILELIST_FAILURE:
      return Object.assign({}, updateCache(state, {auditMsg: "", auditLoading: false, auditError: action.error}, action.customerId))
    case companyConstants.AUDIT_POPULATEFILELIST_SUCCESS:
      return Object.assign({}, updateCache(populateBoards(state, action.allItem, action.customerId), {auditMsg: "", auditLoading: false}, action.customerId), {latestAction: new Date()});

    case customerConstants.UPDATE_CUST_SETTINGS_SUCCESS:
      return Object.assign({}, setBoardSetting(state, action.boards))

    case boardConstants.CLEAR_ERROR_REQUEST:
      return Object.assign({}, updateCache(state, {error: ""}))

    case boardConstants.BOARD_SETCURRENT_SUCCESS:
      return Object.assign({}, setCurrent(state, {boardId: action.id, boardName: action.name, customerId: action.customerId}))

    case boardConstants.BOARD_GETCURRENT_REQUEST:
      return Object.assign({}, state);
    case boardConstants.BOARD_GETCURRENT_SUCCESS:
      return Object.assign({}, setCurrent(state, action.payload))

    case boardConstants.BOARD_GETPREVIEW_REQUEST:
      return Object.assign({}, state);
    case boardConstants.BOARD_GETPREVIEW_SUCCESS:
      return Object.assign({}, setAllBoard(state, action.payload));
    case boardConstants.BOARD_GETPREVIEW_FAILURE:
      return Object.assign({}, state);

    case boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_REQUEST:
      return Object.assign({}, setCustomer(state, action.customerId, true));
    case boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_SUCCESS:
      return Object.assign({}, PopulateAllBinderAndTemplate(state, action.data, action.customerId));
    case boardConstants.POPULATE_ALL_BINDERS_TEMPLATES_FAILURE:
      return Object.assign({}, setCustomer(state, action.customerId, false));

    //case customerConstants.SWITCH_CUSTOMER:
    //  return resetData(state, action.customerId)
    case userConstants.LOGIN_SUCCESS:
      return state //Object.assign({}, state, {customerId: action.payload.customerId !== undefined?action.payload.customerId:state.customerId});

    case userConstants.LOGOUT_REQUEST:
      return Object.assign({});
    default:
      return state
  }
}
