/* global appConfig, __BUILD_DATE__, Version */

export function GetURL() {
  if(window.demo){
    return appConfig.demoURL
  }
  try {
    if (location.origin.includes('dev.athenaboard') && window.useBoardAPI) {
      return window.useBoardAPI;
    }
  } catch { }
  // return "http://localhost:8888/api/V1.0/";
  return appConfig.apiURL
}

export function GetSupportChatURL(token) {
  if (!appConfig.supportChatURL) { return ''; }
  if (!token) { return appConfig.supportChatURL }
  return appConfig.supportChatURL + `?support=true&token=${token}&origin=${window.location.origin}`;
}

export function GetFrontendHelperURL() {
  // return 'https://localhost:18888/v1/';
  return appConfig.frontendHelperURL
}

export function GetWebSocURL() {
  return appConfig.webSocketURL
}

export function isDev(){
  if (appConfig.env === 'dev'){
    return true
  }
  return false
}

export function getHelp(){
  return 1.1
}

export function getVer(){
  return 1.2
}

function getHash(){
  const h = __BUILD_DATE__
  if(h !== undefined)
     return h
  return ""
}

export function getVersion(){
  if (typeof Version === 'function'){
    if (appConfig.env === 'dev')
      return Version()+" "+getHash()
    return Version()
  }
  return ""
}
