var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var AddToBinderActionTypes = {
    ADD_TO_BINDER_SET_BINDER_DATA: 'ADD_TO_BINDER_SET_BINDER_DATA',
    ADD_TO_BINDER_SET_MEMBERSHIP_DATA: 'ADD_TO_BINDER_SET_MEMBERSHIP_DATA',
    ADD_TO_BINDER_TOGGLE_BINDER: 'ADD_TO_BINDER_TOGGLE_BINDER',
    ADD_TO_BINDER_UPDATE_BINDER_PROPERTIES: 'ADD_TO_BINDER_UPDATE_BINDER_PROPERTIES',
    ADD_TO_BINDER_REMOVE_BINDER: 'ADD_TO_BINDER_REMOVE_BINDER',
    ADD_TO_BINDER_REMOVE_FOR_BOARD: 'ADD_TO_BINDER_REMOVE_FOR_BOARD',
    ADD_TO_BINDER_REMOVE_ALL_BINDERS: 'ADD_TO_BINDER_REMOVE_ALL_BINDERS',
    ADD_TO_BINDER_UPDATE_PROCESS_STATE: 'ADD_TO_BINDER_UPDATE_PROCESS_STATE',
};
var INITIAL_STATE = {}; // { [userId] : { binderData: [], binders: { [binderId]: //add to binder state } } }
export var AttendeeTypeConst = 'Attendee';
export var RecipientTypeConst = 'Recipient';
var initialBinderToAddToState = {
    id: '',
    boardId: '',
    type: '',
    status: 'not-started',
    errorMessage: '',
};
function newUserState() { return { binderData: [], binders: {}, memberships: [], processingState: 'binder-select' }; }
function setMembershipData(state, payload) {
    var newState = __assign({}, state);
    try {
        newState[payload.userId].memberships = payload.memberships;
    }
    catch (_a) {
        newState[payload.userId] = __assign(__assign({}, newUserState()), { memberships: payload.memberships });
    }
    return newState;
}
function setBinderData(state, payload) {
    var newState = __assign({}, state);
    try {
        newState[payload.userId].binderData = payload.binderData;
    }
    catch (_a) {
        newState[payload.userId] = __assign(__assign({}, newUserState()), { binderData: payload.binderData });
    }
    return newState;
}
function setProcessState(state, payload) {
    var newState = __assign({}, state);
    try {
        newState[payload.userId].processingState = payload.processingState;
        newState[payload.userId] = __assign({}, newState[payload.userId]);
    }
    catch (e) {
        console.error(e);
        return state;
    }
    return newState;
}
function toggleBinderSelected(state, payload) {
    var _a, _b;
    if (!payload.userId || !payload.binderId) {
        console.error("Missing userId or binderId.");
        return state;
    }
    var newState = __assign({}, state);
    if (!newState[payload.userId]) {
        newState[payload.userId] = { binderData: [], binders: {}, memberships: [], processingState: 'binder-select' };
    }
    if (newState[payload.userId].binders[payload.binderId]) {
        delete newState[payload.userId].binders[payload.binderId];
    }
    else {
        try {
            newState[payload.userId] = {
                memberships: newState[payload.userId].memberships || [],
                binderData: newState[payload.userId].binderData || [],
                processingState: newState[payload.userId].processingState,
                binders: __assign(__assign({}, newState[payload.userId].binders), (_a = {}, _a[payload.binderId] = __assign(__assign({}, initialBinderToAddToState), { id: payload.binderId, boardId: payload.boardId, type: payload.type }), _a))
            };
            if (payload.status != undefined) {
                newState[payload.userId].binders[payload.binderId].status = payload.status;
            }
            if (payload.errorMessage != undefined) {
                newState[payload.userId].binders[payload.binderId].errorMessage = payload.errorMessage;
            }
        }
        catch (e) {
            console.error(e);
            newState[payload.userId] = {
                memberships: newState[payload.userId].memberships,
                binderData: newState[payload.userId].binderData,
                processingState: newState[payload.userId].processingState,
                binders: __assign(__assign({}, newState[payload.userId].binders), (_b = {}, _b[payload.binderId] = __assign(__assign({}, initialBinderToAddToState), { id: payload.binderId, status: 'error', errorMessage: 'Error selecting this binder' }), _b))
            };
        }
    }
    newState[payload.userId].binders = __assign({}, newState[payload.userId].binders);
    return newState;
}
// function updateSelectedBindersForUser(state: BinderAddToReducerState, payload: { userId: string }) {
//     if (!payload.userId) { console.error("Missing userId."); return state };
//     var newState = { ...state };
//     Object.values(newState[payload.userId].binders).forEach(b => {
//         newState = updateBinderProperties(newState, { binderId: b.id, boardId: b.boardId, userId: payload.userId, status: 'processing' });
//     });
//     newState[payload.userId].binders = { ...newState[payload.userId].binders };
//     return newState;
// }
function updateBinderProperties(state, payload) {
    if (!payload.userId || !payload.binderId) {
        console.error("Missing userId or binderId.");
        return state;
    }
    var newState = __assign({}, state);
    if (!newState[payload.userId]) {
        newState[payload.userId] = { binderData: [], binders: {}, memberships: [], processingState: 'binder-select' };
    }
    if (!newState[payload.userId].binders[payload.binderId]) {
        newState[payload.userId].binders[payload.binderId] = __assign({}, initialBinderToAddToState);
    }
    if (newState[payload.userId] && newState[payload.userId].binders[payload.binderId]) {
        Object.keys(payload).forEach(function (k) {
            newState[payload.userId].binders[payload.binderId] = __assign({}, newState[payload.userId].binders[payload.binderId]);
            if (k != 'id' && newState[payload.userId].binders[payload.binderId].hasOwnProperty(k)) {
                newState[payload.userId].binders[payload.binderId][k] = payload[k];
            }
        });
    }
    newState[payload.userId].binders = __assign({}, newState[payload.userId].binders);
    return newState;
}
function deselectBinder(state, payload) {
    if (!payload.userId || !payload.binderId) {
        console.error("Missing userId or binderId.");
        return state;
    }
    var newState = __assign({}, state);
    if (newState[payload.userId] && newState[payload.userId].binders[payload.binderId]) {
        delete newState[payload.userId].binders[payload.binderId];
    }
    newState[payload.userId].binders = __assign({}, newState[payload.userId].binders);
    return newState;
}
function removeAllBindersForBoard(state, payload) {
    if (!payload.userId || !payload.boardId) {
        console.error("Missing userId or boardId.");
        return state;
    }
    if (!state[payload.userId]) {
        return state;
    }
    try {
        var newState = __assign({}, state);
        Object.keys(newState[payload.userId].binders)
            .forEach(function (binderId) {
            if (newState[payload.userId] && newState[payload.userId].binders[binderId] && newState[payload.userId].binders[binderId].boardId == payload.boardId && newState[payload.userId].binders[binderId].status == 'not-started') {
                delete newState[payload.userId].binders[binderId];
            }
        });
    }
    catch (e) {
        console.error(e);
        return state;
    }
    newState[payload.userId].binders = __assign({}, newState[payload.userId].binders);
    return newState;
}
export function addToBinder(state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case AddToBinderActionTypes.ADD_TO_BINDER_TOGGLE_BINDER:
            return toggleBinderSelected(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_UPDATE_BINDER_PROPERTIES:
            return updateBinderProperties(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_REMOVE_BINDER:
            return deselectBinder(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_REMOVE_FOR_BOARD:
            return removeAllBindersForBoard(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_SET_BINDER_DATA:
            return setBinderData(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_SET_MEMBERSHIP_DATA:
            return setMembershipData(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_UPDATE_PROCESS_STATE:
            return setProcessState(state, action.payload);
        case AddToBinderActionTypes.ADD_TO_BINDER_REMOVE_ALL_BINDERS:
            return {};
        default:
            return state;
    }
}
